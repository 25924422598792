const headers = [
  {
    text: 'Nombre Charla',
    value: 'conferenceName',
    sortable: false,
    width: 300
  },
  {
    text: 'Conferencista',
    value: 'speaker',
    sortable: false,
    width: 300
  },
  {
    text: 'Fecha',
    value: 'conference_schedule_time.conference_schedule.availableDate',
    sortable: false,
    width: 120
  },
  {
    text: 'Hora',
    value: 'conference_schedule_time.description',
    sortable: false,
    width: 100
  },
  {
    text: 'Nombre empresa',
    value: 'user.company.name',
    sortable: false,
    width: 250
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: 50
  }
]
export default headers
