<template>
  <v-row justify="center">
    <v-dialog
      v-if="isActive"
      v-model="isActive"
      persistent
      width="50%"
    >
      <v-card>
        <v-card-title>
          <span class="headline"> Detalles de la charla </span>
        </v-card-title>
        <v-card-text>
          <validation-observer ref="form">
            <div class="row">
              <div class="col-12 pb-0">
                <h4 class="mb-4 text--primary">
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    mdi-calendar
                  </v-icon>
                 <strong>Espacio de la charla:</strong>
                </h4>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary"><strong>Fecha:</strong> {{ formatDate(conference.availableDate) }}</p>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary"><strong>Hora:</strong> {{ conference.time }}</p>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary"><strong>Salón:</strong> {{ conference.room }}</p>
              </div>
              <div class="col-12 pb-0 pl-0">
                <h4 class="mb-4 text--primary">
                  <v-icon
                    color="primary"
                    class="mr-1"
                  >
                    mdi-bullhorn
                  </v-icon>
                  <strong>Información de la charla:</strong>
                </h4>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary mb-1"><strong>Nombre:</strong></p>
                <p>{{ conference.conferenceName }}</p>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary mb-1"><strong>Empresa:</strong></p>
                <p>{{ conference.companyName }}</p>
              </div>
              <div class="col-4 py-0 pl-8">
                <p class="text--primary mb-1"><strong>Nombre del conferencista:</strong></p>
                <p>{{ conference.speaker }}</p>
              </div>
              <div class="col-12 py-0 pl-8">
                <p class="text--primary mb-1"><strong>Palabras clave:</strong></p>
                <p>{{ conference.keywords }}</p>
              </div>
              <div class="col-12 py-0 pl-8">
                <p class="text--primary mb-1"><strong>Descripción:</strong></p>
                <p class="justify-center">{{ conference.summary }}</p>
              </div>
            </div>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="close"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

import moment from 'moment'

export default {
  name: 'ConferenceDetailModal',
  data () {
    return {
      conference: null,
      isActive: false
    }
  },
  methods: {
    close () {
      this.isActive = false
      this.rejectPromise()
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async open (conference) {
      this.isActive = true
      this.conference = conference.conference_schedule_time ? {
        availableDate: conference.conference_schedule_time.conference_schedule.availableDate,
        time: conference.conference_schedule_time.time,
        room: conference.conference_schedule_time.conference_schedule.conference_room.name,
        keywords: conference.conference_keywords.map((item) => item.name).join(', '),
        conferenceName: conference.conferenceName,
        speaker: conference.speaker,
        summary: conference.summary,
        companyName: conference?.user?.company?.name ? conference.user.company.name : ''
      } : { ...conference, keywords: conference.keywords.join(', ') }
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    }
  }
}
</script>
