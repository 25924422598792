<template>
  <div>
    <div class="pt-3" v-if="fair">
      <div class="row px-7">
        <div class="col-12 px-md-12 col-md-12 mt-4">
          <h2>Programación charlas técnicas y Ágoras {{ fair.name }}</h2>
        </div>
        <div class="row px-md-12 mt-4">
          <div class="col-12 col-md-4 py-0">
            <v-select
              v-model="params.company"
              :items="companies"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Empresa"
            ></v-select>
          </div>
          <div class="col-12 col-md-4 py-0">
            <v-select
              v-model="params.keywords"
              :items="keywords"
              :multiple="true"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Palabras clave"
            ></v-select>
          </div>
          <div class="col-12 col-md-4 py-0">
            <v-select
              v-model="params.conferenceRoom"
              :items="conferenceRooms"
              item-text="name"
              item-value="id"
              outlined
              dense
              label="Salon"
            ></v-select>
          </div>
          <div class="col-12 col-md-4 py-0">
            <v-text-field
              label="Nombre de Charla"
              v-model="params.conferenceName"
              outlined
              dense
              single-line
              hide-details
            ></v-text-field>
          </div>
          <div class="col-12 col-md-4 py-0">
            <date-picker label="Fecha" v-model="date" :is-range="true" :allowed-dates="availableDates" :key="dateHoursKey"></date-picker>
          </div>
          <div class="col-12 col-md-2 py-0">
            <time-picker label="Hora inicio" v-model="startHour" :key="dateHoursKey"></time-picker>
          </div>
          <div class="col-12 col-md-2 py-0">
            <time-picker label="Hora fin" v-model="endHour" :key="dateHoursKey"></time-picker>
          </div>
        </div>
        <div class="row px-md-12">
          <div class="col-12 d-flex justify-end">
            <v-btn
              color="primary"
              @click="search"
              class="white--text text-none elevation-0 mr-2">
              <v-icon
                left
                dark>
                fa-search
              </v-icon>
              Buscar
            </v-btn>
            <v-btn
              color="default"
              @click="clean"
              class="text-none elevation-0">
              <v-icon
                left
                dark>
                fa-broom
              </v-icon>
              Limpiar
            </v-btn>
          </div>
        </div>
        <div class="col-12 mt-6 px-md-12">
          <div class="text-center" v-if="params">
            <data-table ref="conferenceBookings" :params="params" :url="url" :headers="headers">
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      @click="show(item)"
                      class="ma-2 elevation-0"
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="primary"
                    >
                      <v-icon
                        center
                        dark
                        small
                      >
                        fas fa-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Ver detalles de la charla</span>
                </v-tooltip>
              </template>
            </data-table>
          </div>
        </div>
        <div class="col-12 px-md-12 col-md-3 text-center">
          <h4 class="secondary py-2 rounded">Programación</h4>
        </div>
        <div class="col-12 px-md-16 py-2" v-for="(dates, key) in conferenceScheduleTimes" :key="key">
          <h3 class="my-0">
            <v-icon
              dense
              color="primary darken-2"
            >
              fa-calendar
            </v-icon>
            <span class="ml-2"> {{ formatDate(key) }} </span>
          </h3>
          <v-expansion-panels accordion class="my-4">
            <v-expansion-panel
              v-for="(rooms, indexRoom) in dates"
              :key="rooms.id"
              class="mt-2"
            >
              <v-expansion-panel-header color="primary" >
                <h3 class="white--text">{{ indexRoom }}</h3>
                <template v-slot:actions>
                  <v-icon color="white">
                    mdi-chevron-down
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left custom-thead" width="25%">
                          Nombre charla
                        </th>
                        <th class="text-left custom-thead" width="25%">
                          Conferencista
                        </th>
                        <th class="text-left custom-thead" width="10%">
                          Fecha
                        </th>
                        <th class="text-left custom-thead" width="10%">
                          Hora
                        </th>
                        <th class="text-left custom-thead" minWidth="23%">
                          Nombre empresa
                        </th>
                        <th class="text-left custom-thead" width="7%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="value in rooms"
                        :key="value.id"
                      >
                        <td>{{ value.conferenceName }}</td>
                        <td>{{ value.speaker }}</td>
                        <td>{{ formatDate(value.availableDate) }}</td>
                        <td>{{ value.time.substring(0, 5) }}</td>
                        <td>{{ value.companyName }}</td>
                        <td class="text-center">
                          <v-btn
                            outlined
                            @click="show(value)"
                            class="ma-2 elevation-0"
                            small
                            color="primary"
                          >
                            <v-icon
                              center
                              dark
                              small
                            >
                              fas fa-eye
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>
    <conference-detail-modal ref="conferenceDetailModal"/>
  </div>
</template>
<script>
import crudServiceMixin from '@/mixins/crudServiceMixin'
import {
  PUBLIC_FAIRS_URL,
  PUBLIC_CONFERENCE_BOOKINGS_URL,
  PUBLIC_CONFERENCE_KEYWORDS_URL,
  PUBLIC_CONFERENCE_ROOMS_URL,
  PUBLIC_CONFERENCE_SCHEDULES_URL
} from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import DataTable from '@/components/data-table/DataTable'
import headers from '@/views/public/conferences/data/Headers'
import DatePicker from '@/components/date-picker/DatePicker'
import TimePicker from '@/components/date-picker/TimePicker'
import moment from 'moment'
import ConferenceDetailModal from '@/views/public/conferences/components/ConferenceDetailModal'

export default {
  components: {
    DataTable,
    DatePicker,
    TimePicker,
    ConferenceDetailModal
  },
  async created () {
    const fairSlug = this.$route.params.slug
    try {
      this.showLoader()
      await this.getFairBySlug(fairSlug)
      await this.getConferenceRooms()
      await this.getKeywords()
      await this.getCompanies()
      await this.getConferenceScheduleTimes()
      await this.getAvailableDates()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      conferenceRooms: [],
      conferenceBookingUrl: PUBLIC_CONFERENCE_BOOKINGS_URL,
      conferenceKeywordsUrl: PUBLIC_CONFERENCE_KEYWORDS_URL,
      conferenceRoomsUrl: PUBLIC_CONFERENCE_ROOMS_URL,
      conferenceScheduleTimes: [],
      date: null,
      startHour: null,
      endHour: null,
      fair: null,
      headers,
      keywords: [],
      companies: [],
      availableDates: [],
      dateHoursKey: false,
      params: {
        date: null,
        endHour: null,
        fairId: null,
        keywords: null,
        company: null,
        conferenceRoom: null,
        conferenceName: null,
        startDate: null,
        startHour: null,
        pageSize: 10
      },
      url: PUBLIC_CONFERENCE_BOOKINGS_URL + '/successes'
    }
  },
  methods: {
    clean () {
      this.params.date = null
      this.params.keywords = null
      this.params.company = null
      this.params.conferenceRoom = null
      this.params.conferenceName = null
      this.params.startDate = null
      this.params.date = null
      this.params.startHour = null
      this.params.endHour = null
      this.date = null
      this.startHour = null
      this.endHour = null
      this.dateHoursKey = !this.dateHoursKey
      this.search()
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async getFairBySlug (fairSlug) {
      const response = await this.getById(PUBLIC_FAIRS_URL, fairSlug)
      this.fair = response.data
      this.params.fairId = this.fair.id
    },
    async getConferenceRooms () {
      const response = await this.get(this.conferenceRoomsUrl + '/all', { params: { fairId: this.fair.id } })
      this.conferenceRooms = response.data
    },
    async getConferenceScheduleTimes () {
      const response = await this.get(PUBLIC_CONFERENCE_SCHEDULES_URL + '/successes', { params: { fairId: this.fair.id } })
      this.conferenceScheduleTimes = response.data
    },
    async getKeywords () {
      const response = await this.get(this.conferenceKeywordsUrl + '/all', { params: { fairId: this.fair.id } })
      this.keywords = response.data
    },
    async getCompanies () {
      const response = await this.get(this.url + '/companies', { params: { fairId: this.fair.id } })
      this.companies = response.data
    },
    async getAvailableDates () {
      const response = await this.get(PUBLIC_CONFERENCE_SCHEDULES_URL + '/available-dates', { params: this.fair.id })
      this.availableDates = response.data
    },
    search () {
      this.$refs.conferenceBookings.getDataFromApi()
    },
    show (item) {
      this.$refs.conferenceDetailModal.open(item).then(() => {
      }).catch((error) => {
        throw error
      })
    }
  },
  mixins: [
    crudServiceMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'Conferences',
  watch: {
    date (val) {
      if (val) {
        if (val.from && val.to) {
          this.params.date = []
          this.params.date[0] = val.from
          this.params.date[1] = val.to
        }
      }
    },
    endHour (val) {
      this.params.endHour = val
    },
    startHour (val) {
      this.params.startHour = val
    }
  }
}
</script>
<style>
.v-data-table tr:hover:not(.v-table__expanded__content) {
  background: none !important;
}

table {
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}

table th {
  background-color: #222c33;
  color: white !important;
  font-size: 17px !important;
  height: 40px !important;
}

table td + td {
  border-left: 1px solid #dddddd;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
  margin: 0 !important;
}

.custom-thead {
  background-color: #06EFC8 !important;
  color: #222c33 !important;
}
</style>
